import Loading from '@/components/auth/Loading';
import React, { type FC, useEffect, memo } from '@/lib/teact/teact';
import { getActions, withGlobal } from '@/global';

import type { GlobalState } from '@/global/types';

import '@/global/actions/initial';
import { pick } from '@/util/iteratees';
import { PLATFORM_ENV } from '@/util/environment';
import windowSize from '@/util/windowSize';
import useHistoryBack from '@/hooks/useHistoryBack';
import useCurrentOrPrev from '@/hooks/useCurrentOrPrev';

import AuthPhoneNumber from '@/components/auth/AuthPhoneNumber';
import AuthCode from '@/components/auth/AuthCode.async';
import AuthPassword from '@/components/auth/AuthPassword.async';
import AuthRegister from '@/components/auth/AuthRegister.async';
import AuthQrCode from '@/components/auth/AuthQrCode';

type OwnProps = {
  isActive: boolean;
};

type StateProps = Pick<GlobalState, 'authState'>;

const Auth: FC<OwnProps & StateProps> = ({ isActive, authState }) => {
  const { reset, initApi, returnToAuthPhoneNumber, goToAuthQrCode } = getActions();

  useEffect(() => {
    if (isActive) {
      reset();
      initApi();
    }
  }, [isActive, reset, initApi]);

  const isMobile = PLATFORM_ENV === 'iOS' || PLATFORM_ENV === 'Android';

  const handleChangeAuthorizationMethod = () => {
    if (!isMobile) {
      goToAuthQrCode();
    } else {
      returnToAuthPhoneNumber();
    }
  };

  useHistoryBack(
    (!isMobile && authState === 'authorizationStateWaitPhoneNumber') ||
      (isMobile && authState === 'authorizationStateWaitQrCode'),
    handleChangeAuthorizationMethod,
  );

  // Prevent refresh when rotating device
  useEffect(() => {
    windowSize.disableRefresh();

    return () => {
      windowSize.enableRefresh();
    };
  }, []);

  // For animation purposes
  const renderingAuthState = useCurrentOrPrev(authState !== 'authorizationStateReady' ? authState : undefined, true);

  console.log('DEBUG: auth', renderingAuthState);

  switch (renderingAuthState) {
    case 'authorizationStateWaitCode':
      return <AuthCode />;
    case 'authorizationStateWaitPassword':
      return <AuthPassword />;
    case 'authorizationStateWaitRegistration':
      return <AuthRegister />;
    case 'authorizationStateWaitPhoneNumber':
      return <AuthPhoneNumber />;
    case 'authorizationStateWaitQrCode':
      return <AuthQrCode />;
    default:
      return <Loading />;
  }
};

export default memo(withGlobal<OwnProps>((global): StateProps => pick(global, ['authState']))(Auth));
