import type { GlobalState } from '@/global/types';
import { NewChatMembersProgress, RightColumnContent } from '@/types';

import { getSystemTheme, IS_SINGLE_COLUMN_LAYOUT } from '@/util/environment';
import { selectCurrentMessageList, selectIsPollResultsOpen } from '@/global/selectors/messages';
import { selectCurrentTextSearch } from '@/global/selectors/localSearch';
import { selectCurrentStickerSearch, selectCurrentGifSearch } from '@/global/selectors/symbols';
import { selectIsStatisticsShown } from '@/global/selectors/statistics';
import { selectCurrentManagement } from '@/global/selectors/management';

export function selectIsMediaViewerOpen(global: GlobalState) {
  const { mediaViewer } = global;
  return Boolean(mediaViewer.messageId || mediaViewer.avatarOwnerId);
}

export function selectRightColumnContentKey<T extends GlobalState>(global: T, isMobile?: boolean) {
  return selectIsPollResultsOpen(global)
    ? RightColumnContent.PollResults
    : // : (!IS_SINGLE_COLUMN_LAYOUT || isMobile) && selectCurrentTextSearch(global)
      !IS_SINGLE_COLUMN_LAYOUT && selectCurrentTextSearch(global)
      ? RightColumnContent.Search
      : selectCurrentManagement(global)
        ? RightColumnContent.Management
        : selectIsStatisticsShown(global)
          ? RightColumnContent.Statistics
          : selectCurrentStickerSearch(global).query !== undefined
            ? RightColumnContent.StickerSearch
            : selectCurrentGifSearch(global).query !== undefined
              ? RightColumnContent.GifSearch
              : global.newChatMembersProgress !== NewChatMembersProgress.Closed
                ? RightColumnContent.AddingMembers
                : global.isChatInfoShown && selectCurrentMessageList(global)
                  ? RightColumnContent.ChatInfo
                  : undefined;
}

export function selectIsRightColumnShown<T extends GlobalState>(global: T, isMobile?: boolean) {
  return selectRightColumnContentKey(global, isMobile) !== undefined;
}

export function selectTheme(global: GlobalState) {
  const { theme, shouldUseSystemTheme } = global.settings.byKey;

  return shouldUseSystemTheme ? getSystemTheme() : theme;
}
