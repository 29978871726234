import type { GlobalState } from '@/global/types';
import { selectCurrentMessageList } from '@/global/selectors/messages';
import { buildChatThreadKey } from '@/global/helpers';

export function selectCurrentTextSearch(global: GlobalState) {
  const { chatId, threadId } = selectCurrentMessageList(global) || {};
  if (!chatId || !threadId) {
    return undefined;
  }

  const chatThreadKey = buildChatThreadKey(chatId, threadId);
  const currentSearch = global.localTextSearch.byChatThreadKey[chatThreadKey];
  if (!currentSearch || !currentSearch.isActive) {
    return undefined;
  }

  return currentSearch;
}

export function selectCurrentMediaSearch(global: GlobalState) {
  const { chatId } = selectCurrentMessageList(global) || {};
  if (!chatId) return undefined;

  return global.localMediaSearch.byChatId[chatId];
}
