import React, { type FC, memo } from '@/lib/teact/teact';
import { Bundles } from '@/util/moduleLoader';
import Loading from '@/components/ui/Loading';
import useModuleLoader from '@/hooks/useModuleLoader';

const AuthCodeAsync: FC = () => {
  const AuthCode = useModuleLoader(Bundles.Auth, 'AuthCode');

  return AuthCode ? <AuthCode /> : <Loading />;
};

export default memo(AuthCodeAsync);
