import AuthBrand from '@/components/auth/AuthBrand';
import Button from '@/components/ui/Button';
import { cn } from '@/shared/styles';
import QrCreator from 'qr-creator';
import React, { type FC, useEffect, useRef, memo, useCallback } from '@/lib/teact/teact';
import { getActions, withGlobal } from '@/global';

import type { GlobalState } from '@/global/types';
import type { LangCode } from '@/types';

import { DEFAULT_LANG_CODE, IS_PRODUCTION } from '@/config';
import { setLanguage } from '@/util/langProvider';
import renderText from '@/components/common/helpers/renderText';
// import useLangString from '@/hooks/useLangString';
import useFlag from '@/hooks/useFlag';
import useLang from '@/hooks/useLang';
import { getSuggestedLanguage } from '@/components/auth/helpers/getSuggestedLanguage';

import Loading from '@/components/ui/Loading';

import * as authCommonStyles from '@/components/auth/styles.module.scss';
import * as styles from '@/components/auth/AuthQrCode.module.scss';

type StateProps = Pick<GlobalState, 'connectionState' | 'authState' | 'authQrCode'> & {
  language?: LangCode;
};

const DATA_PREFIX = 'twl://login?token=';

const AuthCode: FC<StateProps> = ({ connectionState, authState, authQrCode, language }) => {
  const { returnToAuthPhoneNumber, setSettingOption } = getActions();

  const suggestedLanguage = getSuggestedLanguage();
  const lang = useLang();

  const qrCodeRef = useRef<HTMLDivElement>(null);
  // const continueText = useLangString(suggestedLanguage, 'ContinueOnThisLanguage');
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  useEffect(() => {
    if (!authQrCode || connectionState !== 'connectionStateReady') return;

    const container = qrCodeRef.current;
    if (!container) return;

    container.innerHTML = '';
    container.classList.remove('pre-animate');

    QrCreator.render(
      {
        text: `${DATA_PREFIX}${authQrCode.token}`,
        radius: 0.5,
        ecLevel: 'M',
        fill: '#EBA802',
        size: 280 * 4,
      },
      container,
    );
  }, [connectionState, authQrCode]);

  useEffect(() => {
    if (connectionState === 'connectionStateReady') {
      void setLanguage(DEFAULT_LANG_CODE);
    }
  }, [connectionState]);

  const handleLangChange = useCallback(() => {
    markIsLoading();

    void setLanguage(suggestedLanguage, () => {
      unmarkIsLoading();

      setSettingOption({ language: suggestedLanguage });
    });
  }, [markIsLoading, setSettingOption, suggestedLanguage, unmarkIsLoading]);

  const isAuthReady = authState === 'authorizationStateWaitQrCode';

  return (
    <div className={authCommonStyles.page}>
      <AuthBrand />

      <section className={authCommonStyles.halfPageWrapper}>
        <div className={cn(authCommonStyles.halfPageContainer, styles.container)}>
          <div className={cn('custom-scroll', styles.qrForm)}>
            {authQrCode ? (
              <div key="qr-container" className={cn(styles.qrContainer, 'pre-animate')} ref={qrCodeRef} />
            ) : (
              <div key="qr-loading" className={cn(styles.qrLoading)}>
                <Loading />
              </div>
            )}

            <h3>{lang('Login.QR.Title')}</h3>

            <ol>
              <li>
                <span>{lang('Login.QR.Help1')}</span>
              </li>
              <li>
                <span>{renderText(lang('Login.QR.Help2'), ['simple_markdown'])}</span>
              </li>
              <li>
                <span>{lang('Login.QR.Help3')}</span>
              </li>
            </ol>

            {isAuthReady && !IS_PRODUCTION && (
              <Button isText onClick={returnToAuthPhoneNumber}>
                {lang('Login.QR.Cancel')}
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      connectionState,
      authState,
      authQrCode,
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      connectionState,
      authState,
      authQrCode,
      language,
    };
  })(AuthCode),
);
