import React, { type FC, memo } from '@/lib/teact/teact';

import Spinner from '@/components/ui/Spinner';
import buildClassName from '@/util/buildClassName';

import '@/components/ui/Loading.scss';

type OwnProps = {
  color?: 'blue' | 'white' | 'black' | 'yellow';
  backgroundColor?: 'light' | 'dark';
  onClick?: NoneToVoidFunction;
};

const Loading: FC<OwnProps> = ({ color = 'yellow', backgroundColor, onClick }) => {
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div className={buildClassName('Loading', onClick && 'interactive')} onClick={onClick}>
      <Spinner color={color} backgroundColor={backgroundColor} />
    </div>
  );
};

export default memo(Loading);
