import React, { type FC, memo } from '@/lib/teact/teact';
import { withGlobal } from '@/global';
import { cn } from '@/shared/styles';

import type { LangCode } from '@/types';
import * as styles from '@/components/auth/AuthBrand.module.scss';
import * as authCommonStyles from '@/components/auth/styles.module.scss';

import downloadAndroidPath from '@/assets/download-android.png';
import downloadIosPath from '@/assets/download-ios.png';
import downloadHuaweiPath from '@/assets/download-huawei.png';
import logo from '@/assets/logo.svg';

enum Download {
  Android = 'https://play.google.com/store/apps/details?id=com.tawasul.messenger',
  iOS = 'https://apps.apple.com/us/app/tawasal-superapp/id1507761438',
  Huawei = 'https://appgallery.huawei.com/app/C105867571',
}

type StateProps = {
  language?: LangCode;
};

const AuthBrand: FC<StateProps> = () => {
  return (
    <section className={authCommonStyles.halfPageWrapper}>
      <div className={cn(authCommonStyles.halfPageContainer, styles.container)}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Tawasal" />
          <span className={styles.logoTitle}>TAWASAL</span>
        </div>

        <div>
          <h1 className={styles.title}>Join Tawasal</h1>
          <p className={styles.subtitle}>Download Tawasal SuperApp now and stay in touch. Anytime. Anywhere.</p>
        </div>

        <div className={styles.downloadButtonsWrapper}>
          <a href={Download.iOS} target="_blank" className={styles.downloadButton} rel="noreferrer">
            <img src={downloadIosPath} alt="Download Tawasal on iOS" />
          </a>

          <a href={Download.Android} target="_blank" className={styles.downloadButton} rel="noreferrer">
            <img src={downloadAndroidPath} alt="Download Tawasal on Android" />
          </a>

          <a href={Download.Huawei} target="_blank" className={styles.downloadButton} rel="noreferrer">
            <img src={downloadHuaweiPath} alt="Download Tawasal on Huawei" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      language,
    };
  })(AuthBrand),
);
